import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ListTable from "../../../components/ListTable/ListTable";
import {FormattedNumber} from "react-intl";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useForm} from "antd/es/form/Form";
import {API} from "aws-amplify";
import dayjs from "dayjs";
import {
    Alert,
    Button,
    Card,
    Col,
    DatePicker,
    Descriptions,
    Divider,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Row,
    Segmented,
    Select,
    Switch,
    Table,
    Tag,
    Typography
} from "antd";
import {CloseOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import TypographyCurrency from "../../../components/CurrencySelect/TypographyCurrency";
import ChartistGraph from "react-chartist";
import CurrencySelect from "../../../components/CurrencySelect/CurrencySelect";
import FileUploader from "../../../components/FileUploader/FileUploader";
import Draggable from "react-draggable";
import BeneficiaryModal from "../../../components/Beneficiaries/BeneficiaryModal";
import {CurrencyLocales} from "../../../AppUtil";
import {notification} from "antd/es";
import axios from "axios";
import {ExposureMonitorUtils as exposureMonitorUtils} from "./exposureMonitorUtils";

const style = theme => ({
    chartistTooltip: {
        position: 'absolute',
        display: 'none',
        padding: '5px',
        background: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        borderRadius: '3px',
        pointerEvents: 'none',
        zIndex: '100',
    }, currency: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 'fit-content',
        '& > .currency-flag': {
            marginRight: '0.5rem', width: 52, height: 26,
        },
    }, currencyLabel: {
        textAlign: 'left', '& > p': {
            marginBottom: -5, fontSize: '0.6rem',
        },
    }
})
export const CurrencyElement = ({classes, currency, value}) => (<div className={classes.currency}>
    <div
        className={`currency-flag currency-flag-${currency ? currency.toLowerCase() : ''}`}
    />
    <div className={classes.currencyLabel}>
        <p>{currency}</p>
        <FormattedNumber
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            value={value}
        />
    </div>
</div>);

const Context = React.createContext({
    name: 'Default',
});

const ExposureMonitor = ({app_state, classes, getColumnSearchProps, history}) => {

    const [payoutForm] = useForm();
    const [transferForm] = useForm();
    const [valueForm] = useForm();
    const [draftForwardForm] = useForm();
    const [api, contextHolder] = notification.useNotification();

    const uploaderRef = useRef();
    const beneficiaryModalRef = useRef();
    const draggableRef = useRef(null);

    const [modalDrag, setModalDrag] = useState({
        disabled: true, bounds: {left: 0, top: 0, bottom: 0, right: 0}
    });

    const [pieCharts, setPieCharts] = useState({
        currencyAverage: {
            labels: [], series: [], data: [], isUncoveredZero: true, forwardGtPayout: false
        }
    });


    const [barCharts, setBarCharts] = useState({
        fxExposure: {
            labels: [], series: [], chartConfig: {
                axisY: {
                    offset: 60
                },
                seriesBarDistance: 15, height: '400px', showPoint: true, axisX: {
                    labelInterpolationFnc: function (value, i, j) {
                        return value;
                    },
                },
            }
        }, currencyPlan: {
            labels: [], series: [], chartConfig: {
                axisY: {
                    offset: 60
                },
                seriesBarDistance: 15, height: '400px', showPoint: true, axisX: {
                    labelInterpolationFnc: function (value, i, j) {
                        return value;
                    },
                },
            }
        }

    });

    const [componentState, setComponentState] = useState({
        transferType: 'PAYOUTS',
        activePayoutId: null,
        isPayoutModalOpen: false,
        isBenModalOpen: false,
        isForwardModalOpen: false,
        isDraftForwardModelOpen: false,
        isListModalOpen: false,
        isTransferModalOpen: false,
        isPayoutListLoading: false,
        beneficiaryList: [],
        currencyBeneficiaryList: [],
        fxCurrency: 5,
        data: [],
        dataList: [],
        monthly: null,
        months: [],
        currentRate: null,
        isDraft: true,
        desired_coverage: null
    });

    const initPrefs = () => {
        if (!app_state.current_client) return true;
        API.post("commons", "/fetch", {
            body: {
                context: 'beneficiaries', fields: ['*'], condition: {client_id: app_state.current_client.id}
            }
        }).then(res => {
            const data = res.filter(i => i.account_currency === componentState.fxCurrency);
            setComponentState(prev => ({...prev, beneficiaryList: res, currencyBeneficiaryList: data}));
        }).catch(err => {
            console.log("Unable to fetch beneficiaries", err);
        });
    }

    const prepareMappedData = (datum) => {
        let data = [];
        if (datum.payouts && datum.payouts.length > 0) {
            const d = datum.payouts.map(item => ({
                id: item.id,
                bid: item.beneficiary_id,
                identifier: 'cashplan_records',
                type: 'PAYOUTS',
                amount_from: null,
                amount: item.payout,
                currencyID: item.currencyID,
                date: item.scheduled,
                rate: null,
                draft: item.draft,
                attachments: (!(item.attachments == null || item.attachments === "")) ? JSON.parse(item.attachments) : []
            }));
            data = [...data, ...d];
        }
        if (datum.transfers && datum.transfers.length > 0) {
            const d = datum.transfers.map(item => ({
                id: item.id,
                identifier: 'transfers',
                bid: item.beneficiary_id,
                type: 'TRANSFERS',
                amount: item.amount_to,
                amount_from: item.amount_from,
                currencyID: item.currency_to_id,
                currencyFromId: item.currency_from_id,
                date: item.settlement_date,
                rate: item.client_rate,
                draft: item.draft,
                attachments: (!(item.attachments == null || item.attachments === "")) ? JSON.parse(item.attachments) : []
            }));
            data = [...data, ...d];
        }
        if (datum.forwards && datum.forwards.length > 0) {
            const d = datum.forwards.map(item => ({
                id: item.id,
                identifier: 'forwards',
                bid: null,
                type: 'FORWARDS',
                amount: item.amount_to,
                amount_from: item.amount_from,
                currencyID: item.currency_to_id,
                currencyFromId: item.currency_from_id,
                date: item.settlement_date,
                rate: item.client_rate,
                draft: item.draft,
                attachments: (!(item.attachments == null || item.attachments === "")) ? JSON.parse(item.attachments) : []
            }));
            data = [...data, ...d];
        }
        if (datum.draftForwards && datum.draftForwards.length > 0) {
            const d = datum.draftForwards.map(item => ({
                id: item.id,
                identifier: 'forwards',
                bid: null,
                type: 'FORWARDS',
                amount: item.amountTo,
                amount_from: null,
                currencyID: item.currencyToID,
                currencyFromId: item.currencyFromID,
                date: item.settlementDate,
                rate: null,
                draft: true
            }));
            data = [...data, ...d];
        }



        const fxExposureList = data.filter(i => !!dayjs(i.date).isAfter(dayjs()));
        data = data.filter(item => item.currencyID === componentState.fxCurrency && !!dayjs(item.date).isAfter(dayjs()));

        console.log(data.filter(item => item.type === 'PAYOUTS'));
        console.log(data.filter(item => item.type === 'TRANSFERS'));
        console.log(data.filter(item => item.type === 'FORWARDS'));

        return {
            data: data,
            fxExposureList: fxExposureList
        };
    }

    const initData = () => {
        if (!app_state.current_client) return true;
        setComponentState(prev => ({...prev, isPayoutListLoading: true }));
        Promise.all([API.post("commons", "/fetch", {
            body: {
                context: 'cashplan_records',
                fields: ['*'],
                condition: {deleted: null, receipt: null, clientID: app_state.current_client.id}
            }
        }), API.post("commons", "/fetch", {
            body: {
                context: 'forwards', fields: ['*'], condition: {
                    deleted: false, client_id: app_state.current_client.id
                }
            }
        }), API.post("commons", "/fetch", {
            body: {
                context: 'draft_forward', fields: ['*'], condition: {
                    deleted: false, clientID: app_state.current_client.id
                }
            }
        }), API.post("commons", "/fetch", {
            body: {
                context: 'transfers', fields: ['*'], condition: {
                    deleted: false, client_id: app_state.current_client.id
                }
            }
        })]).then(([payouts, forwards, draftForwards, transfers]) => prepareMappedData({payouts, forwards, draftForwards, transfers})).then(res => {

            const getUniqueDates = (data) => [
                ...new Map(data.map(item => {
                    const date = dayjs(item.date);
                    return [date.month(), { label: date.format('MMMM'), value: date.month() + 1, year: date.format('YYYY') }];
                })).values()
            ];

            const months = getUniqueDates(res.data);


            setComponentState(prev => ({
                ...prev,
                data: res.data,
                dataList: res.data,
                isPayoutListLoading: false,
                fxExposureList: res.fxExposureList,
                months: months
            }));


            const {eBarLabels = [], eBarSeries = []} = prepareFXExposureChart([...res.fxExposureList], componentState.isDraft);
            let { barLabels = [], barSeries = [] } = { barLabels: [], barSeries: [] };
            if (!componentState.monthly) {
                const result = prepareCurrencyPlanChart(res.data, componentState.isDraft);
                barLabels = result.barLabels;
                barSeries = result.barSeries;
            }
            setBarCharts(prev => ({
                ...prev,
                currencyPlan: {
                    ...prev.currencyPlan, labels: barLabels, series: barSeries
                },
                fxExposure: {
                    ...prev.fxExposure,  labels: eBarLabels, series: eBarSeries
                }
            }));

            const {pieLabels, pieSeries, data, isUncoveredZero} = prepareCurrencyAverageChart(res.data, componentState.isDraft);
            setPieCharts(prev => ({
                ...prev,
                currencyAverage: {
                    ...prev.currencyAverage,
                    series: pieSeries,
                    labels: pieLabels,
                    data: data,
                    isUncoveredZero
                }
            }));
        })
    }

    const prepareFXExposureChart = (data, includeDraft) => {
        const currencyIds = [...new Set(data.map(item => item.currencyID))];
        const labels = currencyIds.map(id => app_state.currency_list.find(i => i.id === id).iso_alpha_3).filter(Boolean);

        const seriesTypes = ['PAYOUTS', 'TRANSFERS', 'FORWARDS'];


        let newData = data;
        if (includeDraft) {
            newData = data.filter(item => !item.draft)
        }

        const eBarSeries = seriesTypes.map(type =>
            labels.map(label => {
                const currencyId = app_state.currency_list.find(i => i.iso_alpha_3 === label).id;
                return newData
                    .filter(i => i.currencyID === currencyId && i.type === type)
                    .reduce((sum, { amount }) => sum + amount, 0);
            })
        );

        return { eBarLabels: labels, eBarSeries };
    };

    const prepareCurrencyPlanChart = (datum, includeDraft) => {
        if (!componentState.monthly) {

            let data = datum.map(i => {
                const item = {...i};
                if (i.type !== 'PAYOUTS') {
                    item.type = 'COVERED';
                }
                return item;
            })



            if (includeDraft) {
                data = data.filter(item => !item.draft)
            }

            const expectedTypes = ['PAYOUTS', 'COVERED'];

            const groupedData = data.reduce((acc, { type, amount, date }) => {
                const formattedDate = dayjs(date).format('MMM YYYY');
                return {
                    ...acc,
                    [type]: {
                        ...(acc[type] || {}),
                        [formattedDate]: ((acc[type] && acc[type][formattedDate]) || 0) + amount,
                    },
                };
            }, {});

            expectedTypes.forEach(type => {
                if (!groupedData[type]) {
                    groupedData[type] = {[dayjs().format('MMM YYYY')]: 0}; // Initialize empty object for missing types
                }
            });

            const labels = Array.from({length: 12}, (_, i) => dayjs().add(i, 'month').format('MMM YYYY'));
            const fillMissingMonths = (dataObject = {}) => labels.reduce((acc, month) => ({
                ...acc,
                [month]: dataObject[month] || 0
            }), {});

            const series = expectedTypes.map(type => [...Object.values(fillMissingMonths(groupedData[type]))]);
            return {
                barLabels: Array.from({length: 12}, (_, i) => dayjs().add(i, 'month').format('MMM YYYY')),
                barSeries: series
            }
        }
    }

    const prepareCurrencyAverageChart = (data, includeDraft) => {

        let payout = 0;
        let covered = 0;

        if (!includeDraft) {
            payout = data.filter(i => i.type === 'PAYOUTS').reduce((acc, obj) => acc + obj.amount, 0);
        } else {
            payout = data.filter(i => i.type === 'PAYOUTS' && !i.draft).reduce((acc, obj) => acc + obj.amount, 0);
        }

        if (!includeDraft) {
            covered = data.filter(i => i.type !== 'PAYOUTS').reduce((acc, obj) => acc + obj.amount, 0);
        } else {
            covered = data.filter(i => i.type !== 'PAYOUTS' && !i.draft).reduce((acc, obj) => acc + obj.amount, 0);
        }

        const uncovered = payout - covered;

        const series = [
            {label: 'Uncovered Amount', value: uncovered},
            {label: 'Covered Amount', value: covered}
        ]

        const seriesB = [
            {label: 'Uncovered', value: uncovered},
            {label: 'Covered', value: covered}
        ]

        const total = covered + uncovered;

        const labels = series.map(i => {
            const percentage = ((i.value / total) * 100).toFixed(0);
            return `${i.label} = ${percentage}%`;
        });

        const labels2 = seriesB.map(i => {
            const percentage = ((i.value / total) * 100).toFixed(0);
            return `${i.label} = ${percentage}%`;
        });

        const desc = [...series];
        desc.push({label: 'Payments Loaded', value: payout});

        return {
            pieSeries: seriesB,
            pieLabels: labels2.map((val, ind) => series[ind].value === 0 ? ' ' : val),
            data: desc,
            isUncoveredZero: uncovered < 0
        }
    }

    const onEditBtnClick = (payoutId) => {
        const loading = message.loading(`Loading id #${payoutId}. Please wait..`, 0);
        API.post("commons", "/fetch", {
            body: {
                context: 'cashplan_records', condition: {id: payoutId}, fields: ['*']
            }
        }).then(res => {
            const [d] = res;
            setComponentState(prev => ({
                ...prev, isPayoutModalOpen: true, activePayoutId: payoutId
            }));
            if (d.attachments == null && d.attachments === "") {
                const bottle = (JSON.parse(d.attachments) || []).map(i => ({
                    name: i, uid: i, status: 'done'
                }));
                uploaderRef.current.setFileList(bottle);
            }
            payoutForm.setFieldsValue({...d, date: dayjs(d.date)});
        }).catch(err => {
            console.log(err);
            message.error("Failed to load. Please try again..");
        }).finally(() => loading());
    }

    const buildColumns = () => {
        return exposureMonitorUtils.buildPayoutColumns(getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, onViewDocumentsBtnClick, history);
    }

    const buildForwardColumns = () => {
        return exposureMonitorUtils.buildCoveredColumns(getColumnSearchProps, componentState, app_state, sendForward, classes, initData, onEditBtnClick, onViewDocumentsBtnClick, history);
    }

    const onViewDocumentsBtnClick = (attachments) => {
        Modal.confirm({
            title: 'Attachments',
            width: 700,
            content: <Row gutter={[16, 16]}>
                <Divider />
                {attachments.map(i => <>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <FileUploader previewOnly={true}
                                      thumbType={'picture-card'}
                                      uploaderType={'PDF'}
                                      fetchFileList={() => {
                                          const file = {
                                              name: i,
                                              uid: i,
                                              status: 'done'
                                          }
                                          return Promise.resolve([file]);
                                      }}></FileUploader>
                    </Col>
                </>)}
            </Row>,
            cancelButtonProps: { hidden: true },
            maskClosable: true
        })
    }

    const setCurrentRate = async () => {
        if (componentState.fxCurrency != null && app_state.currency_list.length > 0) {
            const currency = app_state.currency_list.find(i => i.id === componentState.fxCurrency);
            await axios
                .get(`https://apilayer.net/api/live?access_key=a4eb7fd0501842eb4d4712cc459cae5f&currencies=${currency.iso_alpha_3}&source=AUD&format=1`).then(res => {
                    const rate = res.data.quotes[`AUD${currency.iso_alpha_3}`];
                    setComponentState(prev => ({...prev, currentRate: rate }));
                });
        } else {
            setComponentState(prev => ({...prev, currentRate: null}));
        }
    }

    useEffect(() => {
        initPrefs();
        initData();
    }, [app_state.current_client]);

    useEffect(() => {
        initData();
        setCurrentRate();
    }, [componentState.fxCurrency]);

    useEffect(() => {
        if (componentState.monthly) {
            reConstructMonthlyExposure(componentState.monthly, componentState.isDraft);
        } else {
            initData();
        }
    }, [componentState.monthly, componentState.isDraft]);

    useEffect(() => {
        const chart1 = document.querySelector('.ct-chart-tBar');
        const chart2 = document.querySelector('.ct-chart-mBar');

        [chart1, chart2].forEach((chart, index) => {
            const tooltip = document.createElement('div');
            tooltip.className = `${classes.chartistTooltip}`;
            document.body.appendChild(tooltip);

            if (chart) {
                chart.addEventListener('mouseover', (event) => {
                    if (event.target.classList.contains('ct-bar')) {
                        const value = event.target.getAttribute('ct:value');
                        const meta = event.target.getAttribute('meta');
                        const label = event.target.getAttribute('hoverLabel');

                        let str = '';
                        if  (componentState.data.length > 0) {
                            const currency = componentState.fxCurrency;
                            const payouts = componentState.fxExposureList
                                .filter(i => i.currencyID === currency && i[index] != null && dayjs(i.date).format('MM/YYYY') === meta);
                            payouts.forEach(i => {
                                str += dayjs(i.date).format('DD/MM/YYYY') + ":" + i.amount + `<br />`;
                            })
                        }
                        tooltip.innerHTML = `${label}: ${value}<br />${meta}<br /> ${str}`;
                        tooltip.style.display = 'block';
                        tooltip.style.left = `${event.pageX}px`;
                        tooltip.style.top = `${event.pageY - 40}px`;
                    }
                });

                chart.addEventListener('mouseout', () => {
                    tooltip.style.display = 'none';
                });

                chart.addEventListener('mousemove', (event) => {
                    tooltip.style.left = `${event.pageX}px`;
                    tooltip.style.top = `${event.pageY - 40}px`;
                });
            }
        })


        const pie1 = document.querySelector('.ct-pie-chart');
        const pie2 = document.querySelector('.ct-pie-chart-2');

        if (pie1 && pie2) {

            [pie1, pie2].forEach((pie, index) => {

                const legendContainer = document.getElementById(`chart-legend-${index}`);
                legendContainer.innerHTML = "";
                const series = pie.querySelectorAll('.ct-series');
                const labels = pieCharts.currencyAverage.labels;

                series.forEach((serie, index) => {
                    const legendItem = document.createElement('div');
                    legendItem.classList.add('legend-item');

                    legendItem.style.display = 'flex';
                    legendItem.style.alignItems = 'center';
                    legendItem.style.marginBottom = '5px';
                    legendItem.style.marginRight = '10px';

                    const label = document.createElement('span');
                    const labelss = labels[index];
                    label.textContent = labels[index]; // Add corresponding label

                    if (labelss != null) {
                        const img = document.createElement('img');
                        img.setAttribute('src', `https://fwwportal-branding.s3.us-east-1.amazonaws.com/currencies/${labelss.toLowerCase()}.png`);
                        img.setAttribute('width', '15px');
                        img.setAttribute('height', '10px');
                        img.style.marginRight = '5px';
                        legendItem.appendChild(img)
                    }
                    legendItem.appendChild(label);
                    legendContainer.appendChild(legendItem);
                })
            })
        }
    }, [componentState.fxCurrency]);

    const renderTopBlock = () => {
        return <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Form.Item label={'Currency'} name={'selected_currency'} style={{marginBottom: 0}}>
                <Select allowClear={true}
                        showSearch={true}
                        defaultValue={componentState.fxCurrency}
                        style={{width: '250px'}}
                        optionFilterProp={'alt'}
                        options={app_state.currency_list.filter(i => i.id !== 1).map(i => ({
                            ...i,
                            label: <TypographyCurrency
                                iso_alpha_3={i.iso_alpha_3}>[{i.iso_alpha_3}] {i.full_name}</TypographyCurrency>,
                            value: i.id,
                            alt: `${i.full_name} ${i.iso_alpha_3}`
                        }))}
                        onChange={((val, datum) => setComponentState(prev => ({...prev, fxCurrency: val, monthly: null })))}
                        placeholder={'-- SELECT --'}></Select>
            </Form.Item>
            <span>
                Hypothetical Mode:   [ ON: <Switch defaultChecked={componentState.isDraft} onChange={e => setComponentState(prev => ({...prev, isDraft: e }))}/> OFF ]
            </span>
            <div style={{display: 'flex', alignItems: 'start', gap: 5}}>
                <Button type={'primary'}
                        size={'small'}
                        icon={<PlusOutlined/>}
                        onClick={() => {
                            setComponentState(prev => ({
                                ...prev,
                                isPayoutModalOpen: true,
                                activePayoutId: null,
                                currencyBeneficiaryList: componentState.currencyBeneficiaryList
                            }))
                        }}>
                    Add Payment
                </Button>
                <Button type={'primary'}
                        size={'small'}
                        icon={<PlusOutlined/>}
                        onClick={() => {
                            setComponentState(prev => ({
                                ...prev, isForwardModalOpen: true
                            }))
                        }}>
                    Request Cover
                </Button>
                {!componentState.isDraft &&
                <Button type={'primary'}
                        size={'small'}
                        icon={<PlusOutlined/>}
                        onClick={() => {
                            setComponentState(prev => ({
                                ...prev, isDraftForwardModelOpen: true
                            }))
                        }}>
                    Add Draft Forward
                </Button>
                }
            </div>
        </div>
    }

    const getSelectedCurrency = () => {
        const currency = app_state.currency_list.find(item => item.id === componentState.fxCurrency);
        if (currency != null) {
            return currency.iso_alpha_3
        } else {
            return '-'
        }
    }

    const reConstructMonthlyExposure = ({year, month}, includeDraft) => {

        if (year && month != null) {
            const formattedMonth = `${year}-${String(month).padStart(2, '0')}`; // Format month to 'YYYY-MM'

            const types = ['PAYOUTS', 'COVERED']; // Add more types if necessary

            let data = componentState.data.map(i => {
                const item = {...i};
                if (i.type !== 'PAYOUTS') {
                    item.type = 'COVERED';
                }
                return item;
            });

            if (includeDraft) {
                data = data.filter(item => item.draft === false)
            }

            const groupedData = data.reduce((acc, { type, amount, date }) => {
                const formattedDate = dayjs(date).format('YYYY-MM-DD'); // Full date for filtering
                if (formattedDate.startsWith(formattedMonth)) { // Filter only dates from the passed month and year
                    return {
                        ...acc,
                        [type]: {
                            ...(acc[type] || {}),
                            [formattedDate]: ((acc[type] && acc[type][formattedDate]) || 0) + amount, // Sum data by day
                        },
                    };
                }
                return acc;
            }, {});

            types.forEach(type => {
                if (!groupedData[type]) {
                    groupedData[type] = {[dayjs().format('YYYY-MM-DD')]: 0}; // Initialize missing type
                }
            });

            const daysInMonth = dayjs(`${year}-${month}`, 'YYYY-MM').daysInMonth(); // Number of days in the passed month

            const labels = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());

            const fillMissingDays = (dataObject = {}) => labels.reduce((acc, day) => {
                const fullDate = dayjs(`${year}-${month}-${day}`).format('YYYY-MM-DD');
                return {
                    ...acc,
                    [fullDate]: dataObject[fullDate] || 0 // Fill missing days with 0
                };
            }, {});

            const series = types.map(type => [...Object.values(fillMissingDays(groupedData[type]))]);

            const filteredData = componentState.data.filter(item => {
                const itemDate = dayjs(item.date);
                return itemDate.year() === parseInt(year) && itemDate.month() + 1 === parseInt(month);
            });


            setBarCharts(prev => ({
                ...prev,
                currencyPlan: {
                    ...prev.currencyPlan, labels: labels, series: series
                }
            }));
            setComponentState(prev => ({...prev, dataList: filteredData }));

            // ====================== For Pie chart ==================================

            let payout = 0;
            let covered = 0;
            if (!includeDraft) {
                payout = data.filter(i => i.type === 'PAYOUTS').reduce((acc, obj) => acc + obj.amount, 0);
            } else {
                payout = data.filter(i => i.type === 'PAYOUTS' && !i.draft).reduce((acc, obj) => acc + obj.amount, 0);
            }

            if (!includeDraft) {
                covered = data.filter(i => i.type !== 'PAYOUTS').reduce((acc, obj) => acc + obj.amount, 0);
            } else {
                covered = data.filter(i => i.type !== 'PAYOUTS' && !i.draft).reduce((acc, obj) => acc + obj.amount, 0);
            }

            const uncovered = payout - covered;

            const pieSeries = [
                {label: 'Uncovered', value: uncovered},
                {label: 'Covered', value: covered}
            ]

            const total = covered + uncovered;

            const pieLabels = pieSeries.map(i => {
                const percentage = i.value < total ? ((i.value / total) * 100).toFixed(0) : 100;
                return `${i.label} = ${percentage}%`;
            });

            const desc = [...pieSeries];
            desc.push({label: 'Total Payout', value: payout});

            const currencyAvg = {
                series: pieSeries,
                labels: pieLabels.map((val, ind) => pieSeries[ind].value === 0 ? ' ' : val),
                data: desc,
                isUncoveredZero: uncovered < 0
            };
            setPieCharts(prev => ({
                ...prev, currencyAverage: {...prev, ...currencyAvg}
            }));
        }
    }

    const listener = (strokeWidth = null) => {

        return {
            draw: (data) => {
                if (data.type === 'bar') {

                    if (data.seriesIndex === 0) {
                        data.element.attr({
                            style: 'stroke: #f4c63d;' // Blue color for first series
                        });
                    }
                    // Apply color for second series
                    if (data.seriesIndex === 1) {
                        data.element.attr({
                            style: 'stroke: #00bcd4;' // Red color for second series
                        });
                    }
                    const xAxisLabel = data.axisX.ticks[data.index];

                    data.element._node.addEventListener('click', () => {
                        const date = dayjs(xAxisLabel).format('YYYY-MM');
                        const mth = date.split('-')[1];
                        if (mth != null) {
                            setComponentState(prev => ({
                                ...prev,
                                monthly: {
                                    year: date.split('-')[0],
                                    month: date.split('-')[1],
                                    label:  mth != null ? dayjs(`${date.split('-')[1]}`, 'M').format('MMMM') : null
                                }
                            }));
                        }
                    });


                    data.element.attr({
                        'meta': xAxisLabel,
                        'series': data.seriesIndex,
                        'hoverLabel': data.seriesIndex === 0 ? 'Payouts' : (data.seriesIndex === 1 ? 'Covered' : 'Forwards'),
                    });

                }

                // if (data.type === 'label' && data.axis.units.pos === 'x') {
                //     data.element.empty()._node.innerHTML = data.text.length === 3 ? `
                // <span class="ct-label ct-horizontal ct-end" style="width: 50px; height: 20px">${data.text}</span>
                // ` : `<span class="ct-label ct-horizontal ct-end" style="width: 50px; height: 20px">${data.text}</span>`;
                // }
            }
        }
    }

    const handleSubmit = (payload) => {
        const loading = message.loading("Saving payout record. Please wait..", 0);
        if (!componentState.activePayoutId) {
            payload.recordCreated = dayjs(new Date()).startOf("day");
        }
        payload.clientID = app_state.current_client.id;
        payload.attachments = JSON.stringify(payload.attachments);

        const request = componentState.activePayoutId != null ? API.post("commons", "/update", {
            body: {
                context: 'cashplan_records',
                data: payload,
                condition: {id: componentState.activePayoutId}
            }
        }) : API.post("commons", "/insert", {
            body: {
                context: 'cashplan_records',
                data: payload
            }
        });
        request.then(() => {
            message.success("Payout record saved");
            setComponentState(prev => ({...prev, activePayoutId: null, isPayoutModalOpen: false }));
            initData();
        }).catch(err => {
            console.log(err);
            message.error("Unable to save payout record. Please try again..");
        }).finally(() => loading());
    }


    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggableRef.current.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setModalDrag(prev => ({
            ...prev,
            bounds: {
                left: -targetRect.left + uiData.x,
                right: clientWidth - (targetRect.right - uiData.x),
                top: -targetRect.top + uiData.y,
                bottom: clientHeight - (targetRect.bottom - uiData.y),
            }
        }));
    };

    const handleForwardsSubmit = async (values) => {

        if (componentState.transferType === 'SPOT') {
            const fromCurrency = app_state.currency_list.find(item => values.from_currency === item.id).iso_alpha_3;
            const toCurrency = app_state.currency_list.find(item => values.to_currency === item.id).iso_alpha_3;
            history.push(`/shortcut_transfer/${toCurrency}-${fromCurrency}--${values.amount}-----`);
        } else {
            sendForward(values);
        }
    }

    const sendForward = (values) => {
        Modal.confirm({
            title: 'Are you sure ?',
            content: 'Please confirm that you are requesting a forward ?',
            onOk: async () => {

                let beneficiary = '-';
                if (values.beneficiary_id != null) {
                    beneficiary = componentState.beneficiaryList.find(i => i.id === values.beneficiary_id);
                    beneficiary = `${beneficiary.nickname} (#${beneficiary.id})`
                }
                await API.post("commons", "/mail", {
                    body: {
                        to: `Portal Notifications <portalNotifications@forexworldwide.com>`,
                        subject: 'Forward Request from client # ' + app_state.current_client.id,
                        header: ['New Forward Quote Request'],
                        data: [
                            {
                                label: 'Client',
                                value: `${app_state.current_client.nickname} #${app_state.current_client.id}`
                            },
                            {
                                label: 'Currency From',
                                value: values.from_currency ? app_state.currency_list.find(item => item.id === values.from_currency).iso_alpha_3 : '-'
                            },
                            {
                                label: 'Currency To',
                                value: values.to_currency ? app_state.currency_list.find(item => item.id === values.to_currency).iso_alpha_3 : '-'
                            },
                            {label: 'Amount', value: values.amount},
                            {label: 'Description', value: values.description},
                            {label: 'Settlement', value: values.payment_due_date},
                            {label: 'Beneficiary', value: beneficiary},
                        ],
                        footer: []
                    }
                });
                transferForm.resetFields();
                message.success("Forwards request has been sent to the admin.");
                setComponentState(prev => ({...prev, isForwardModalOpen: false}));
                api.info({
                    message: `Forward Request Alert !`,
                    description: <Context.Consumer>{() => `Our staff will be in touch to confirm this forward shortly.`}</Context.Consumer>,
                    placement: 'bottomLeft',
                });
            }
        });
        const loading = message.loading("Creating forwards. Please wait...", 0);
        try {
            // if needed do do something after sending email;
        } catch (e) {
            console.log(e);
            message.error("Failed to send forwards. Please try again...");
            setComponentState(prev => ({...prev, isForwardModalOpen: false}));
        } finally {
            loading()
        }
    }

    const renderAverageRate = () => {

        console.log(componentState.dataList);
        const data = componentState
            .dataList.filter(i => i.type !== 'PAYOUTS' && !i.draft);

        console.log("main data", data);
        const total = data.reduce((acc, item) => acc + item.amount, 0);
        const total_f = data.reduce((acc, item) => acc + item.amount_from, 0);

        return total !== 0 ? <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 10 }}>
            <Alert style={{ width: 200 }} type={'info'} message={
                <>
                    <Descriptions column={1}>
                        <Descriptions.Item label={'Average Rate'} style={{ margin: 0, padding: 0 }}>{(total/total_f).toFixed(4)}</Descriptions.Item>
                    </Descriptions>
                </>
            }/>
        </div> : <></>
    }

    const buildPayoutData = () => {
        // console.log(componentState.dataList);
        let payouts = componentState
            .dataList.filter(i => i.type === 'PAYOUTS' && !i.draft);
        if (!componentState.isDraft) {
            payouts = componentState.dataList.filter(i => i.type === 'PAYOUTS');
        }
        // console.log(payouts);
        return payouts;
    }

    const buildForwardData = () => {
        // console.log(componentState.dataList);
        let payouts = componentState
            .dataList.filter(i => i.type === 'FORWARDS' && !i.draft);
        if (!componentState.isDraft) {
            payouts = componentState.dataList.filter(i => i.type === 'FORWARDS');
        }
        // console.log(payouts);
        return payouts;
    }

    const handleDraftForwardSubmit = (values) => {
        const loading = message.loading("saving draft forward. Please wait..", 0);
        values.clientID = app_state.current_client.id;
        API.post("commons", "/insert", {
            body: {
                context: 'draft_forward',
                data: values,
            }
        }).then(res => {
            console.log("Draft Forward Saved");
            draftForwardForm.resetFields();
            setComponentState(prev => ({...prev, isDraftForwardModelOpen: false }));
            initData();
        }).catch(err => {
            console.log(err);
            message.error("Unable to save draft forward. Please try again...");
        }).finally(() => loading());
    }

    const contextValue = useMemo(() => ({ name: 'Forward Notification' }), []);

    return <Context.Provider value={contextValue}>
        {contextHolder}
        <Card title={renderTopBlock()} style={{ marginBottom: '10px' }}>
            <Row gutter={[16, 16]} justify={'center'}>
                {
                    pieCharts.currencyAverage.data.reduce((acc, i) => acc + i.value, 0) > 0 ?
                        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                            {
                                pieCharts.currencyAverage.forwardGtPayout
                                    ? <>Your covered amount is greater than your payouts inputted</>
                                    : <><h3 style={{textAlign: 'center'}}>AUD / {getSelectedCurrency()} Coverage</h3>
                                        <h6 style={{textAlign: 'center'}}>
                                            {componentState.monthly ? '[Single Month Coverage Forecast]' : '[12-mth Forward Coverage Forecast]'}
                                        </h6>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            gap: 50,
                                            alignItems: 'center',
                                            flexDirection: 'column'
                                        }}>
                                            <div>
                                                {
                                                    !pieCharts.currencyAverage.isUncoveredZero && <ChartistGraph data={pieCharts.currencyAverage}
                                                                   style={{width: '200px'}}
                                                                   className={'ct-pie-chart-2'}
                                                                   listener={{ draw: (data) => {
                                                                           if (data.type === 'slice') {
                                                                               const sliceIndex = data.index; // Get the slice index (0, 1, 2, ...)
                                                                               if (sliceIndex === 0) {
                                                                                   data.element.attr({
                                                                                       style: 'fill: #f4c63d;' // Blue color for first series
                                                                                   });
                                                                               }
                                                                               // Apply color for second series
                                                                               if (sliceIndex === 1) {
                                                                                   data.element.attr({
                                                                                       style: 'fill: #00bcd4;' // Red color for second series
                                                                                   });
                                                                               }
                                                                           }
                                                                       }}}
                                                                   options={{
                                                                       height: 250, labelInterpolationFnc: function (value) {
                                                                           return value;
                                                                       },
                                                                   }} type={'Pie'}/>
                                                }
                                                <div id={'chart-legend-1'} style={{display: 'flex', gap: 5}}></div>
                                            </div>
                                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                {<Descriptions column={1} contentStyle={{margin: 0, padding: 0}}>
                                                    {componentState.fxCurrency && pieCharts.currencyAverage.data.map(i => (
                                                        <Descriptions.Item style={{marginBottom: 0, paddingBottom: 0}}
                                                                           label={i.label}>
                                                            {new Intl.NumberFormat(CurrencyLocales[getSelectedCurrency()], {
                                                                currency: getSelectedCurrency(),
                                                                style: 'currency',
                                                                useGrouping: true,
                                                                currencyDisplay: 'narrowSymbol',
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2
                                                            }).format(i.value)}
                                                        </Descriptions.Item>))}
                                                </Descriptions>}
                                                {/*<div>*/}
                                                {/*    {*/}
                                                {/*        !pieCharts.currencyAverage.isUncoveredZero &&*/}
                                                {/*        <Alert type={'info'} message={'Uncovered is Zero'}></Alert>*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                            </div>
                                            <Form form={valueForm}>
                                                <h5>Coverage Planning</h5>
                                                <p>
                                                    Specify a desired coverage % to allow our Exposure Monitor to assist you with your forward requirements.<br/>
                                                    [NOT IMPLEMENTED]
                                                </p>
                                                <Form.Item label={'Desired Coverage %'} name={'desired_coverage'} rules={[
                                                    {
                                                        required: false,
                                                        message: 'Please input a number between 1 and 100!',
                                                    },
                                                    {
                                                        validator: (_, value) =>
                                                            !value || (value >= 1 && value <= 100)
                                                                ? Promise.resolve()
                                                                : Promise.reject(new Error('Value must be between 1 and 100!')),
                                                    },
                                                ]}>
                                                    <Input onChange={(e) => {
                                                        const data = valueForm.getFieldValue('desired_coverage');
                                                        setComponentState(prev => ({...prev, desired_coverage: data }));
                                                    }} />
                                                </Form.Item>
                                            </Form>
                                            {componentState.currentRate != null ? <Typography.Text style={{ color: 'red' }}>Current AUD/{getSelectedCurrency()} Rate: {componentState.currentRate}</Typography.Text> : <></>}
                                        </div>
                                    </>
                            }
                        </Col> : <>
                        {/*Alert === any kind of message*/}
                        </>
                }
            <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
                {
                    componentState.fxCurrency ?
                        <div>
                            <h3 style={{textAlign: 'center'}}>
                                Exposure Monitoring <small>{componentState.monthly ? '(daily)' : '(12-month)'}</small>
                            </h3>
                                {
                                    componentState.monthly && componentState.monthly.label &&
                                    <h3 style={{textAlign: 'center'}}>({componentState.monthly.label})</h3>
                                }
                                { componentState.monthly &&
                                    <div style={{display: 'flex', justifyContent: 'end', cursor: 'pointer'}}>
                                        <Tag color={'red'} icon={<CloseOutlined/>} onClick={() => {
                                            setComponentState(prev => ({...prev, monthly: null}));
                                        }}>Back to Monthly Grouping</Tag>
                                    </div>
                                }
                                <ChartistGraph
                                    key={componentState.fxCurrency}
                                    style={{width: '100%', fontWeight: 600}}
                                    className={'ct-chart-tBar'}
                                    data={barCharts.currencyPlan}
                                    type="Bar"
                                    listener={listener(14)}
                                    options={barCharts.currencyPlan.chartConfig}
                                />
                                {/*<div>*/}
                                {/*    <Radio.Group optionType="button"*/}
                                {/*                 buttonStyle="solid"*/}
                                {/*                 onChange={e => {*/}
                                {/*                     const d = componentState.months.find(item => e.target.value === item.value);*/}
                                {/*                     setComponentState(prev => ({*/}
                                {/*                         ...prev,*/}
                                {/*                         monthly: { ...prev.monthly, year: d.year, month: d.value, label: d.label }*/}
                                {/*                     }));*/}
                                {/*                 }}*/}
                                {/*                 options={componentState.months}></Radio.Group>*/}
                                {/*</div>*/}
                            </div> : <div>
                                <h3 style={{textAlign: 'center'}}>Global FX Exposure</h3>
                                <h6 style={{textAlign: 'center'}}>[12-mth Forward Coverage Forecast]</h6>
                                <ChartistGraph
                                    style={{width: '100%', margin: 20, fontWeight: 600}}
                                    data={barCharts.fxExposure}
                                    type="Bar"
                                    className={'ct-chart-mBar'}
                                    listener={listener()}
                                    options={barCharts.fxExposure.chartConfig}
                                />
                            </div>
                    }

                </Col>
            </Row>
        </Card>
        <Card title={'Exposure Monitor Data'}>
            <Segmented defaultValue={componentState.transferType}
                       block={true}
                       style={{marginBottom: '10px'}}
                       options={[
                        {label: 'Scheduled Payments', value: 'PAYOUTS'},
                        {label: 'Coverage (transfers and forwards)', value: 'COVERED'}]}
                       onChange={(val) => setComponentState(prev => ({...prev, transferType: val }))}></Segmented>
            {
                componentState.transferType !== 'PAYOUTS'  ? renderAverageRate(): <></>
            }
            {
                componentState.transferType === 'PAYOUTS' && <>
                    <Table rowKey={'id'}
                           key={app_state.current_client ? app_state.current_client.id : 0}
                           columns={buildColumns()}
                           size="small"
                           summary={(data) => {
                               const total = data.reduce((acc, item) => acc + item.amount, 0);
                               const total_f = data.reduce((acc, item) => acc + item.amount_from, 0);
                               return <Table.Summary.Row>
                                   <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                   <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                   <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                   <Table.Summary.Cell index={8}>Total: {new Intl.NumberFormat('en-Au', {
                                       minimumFractionDigits: 2,
                                       maximumFractionDigits: 2,
                                       useGrouping: true
                                   }).format(total)}</Table.Summary.Cell>
                               </Table.Summary.Row>
                           }}
                           dataSource={buildPayoutData()}
                           loading={componentState.isPayoutListLoading}></Table>
                </>
            }
            {
                componentState.transferType !== 'PAYOUTS' && <>
                    <Table rowKey={'id'}
                           key={app_state.current_client ? app_state.current_client.id : 0}
                           columns={buildForwardColumns()}
                           size="small"
                           summary={(data) => {
                               const total = data.reduce((acc, item) => acc + item.amount, 0);
                               const total_f = data.reduce((acc, item) => acc + item.amount_from, 0);
                               return <Table.Summary.Row>
                                   <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                   <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                   <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                   <Table.Summary.Cell index={3}>Total: {new Intl.NumberFormat('en-Au', {
                                       minimumFractionDigits: 2,
                                       maximumFractionDigits: 2,
                                       useGrouping: true
                                   }).format(total)}</Table.Summary.Cell>
                               </Table.Summary.Row>
                           }}
                           dataSource={buildForwardData()}
                           loading={componentState.isPayoutListLoading}></Table>
                </>
            }
        </Card>
        <Modal visible={componentState.isPayoutModalOpen}
               style={{top: 3}}
               title={componentState.activePayoutId ? `Update Payout #${componentState.activePayoutId} Record` : 'Add a New Payment'}
               okText={
                   componentState.activePayoutId
                       ? `Update Payout`
                       : `Add Payout`
               }
               onCancel={() => {
                   payoutForm.resetFields();
                   uploaderRef.current.clearList();
                   setComponentState(prev => ({
                       ...prev,
                       isPayoutModalOpen: false,
                       activePayoutId: null
                   }));
               }}
               onOk={() => payoutForm.submit()}>
            <p>
                Fill in the details below to add a new payment (future invoice) into the system.
            </p>
            <Divider/>
            <Form layout={'vertical'} initialValues={{ currencyID: componentState.fxCurrency, attachments: [] }} name={'payout-form'} form={payoutForm} onFinish={handleSubmit}>
                <Form.Item label={'Payment Due Date'} name={'scheduled'} normalize={(dayjsValue) =>
                    dayjsValue && dayjsValue.startOf("day").format('YYYY-MM-DD')
                }
                           getValueProps={(value) =>
                               value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                           }>
                    <DatePicker format={'DD/MM/YYYY'}/>
                </Form.Item>
                <Divider/>
                <span style={{ display: 'block', marginBottom: '10px' }}>
                        <CurrencySelect state={{ currencies: app_state.currency_list.filter(i => i.id !== 1) || [] }}
                                        rightLabel={'Enter payment amount'}
                                        onCurrencyChange={(val, d) => {
                                            const benes = componentState.beneficiaryList.filter(i => i.account_currency === d);
                                            setComponentState(prev => ({...prev, currencyBeneficiaryList: benes }));
                                        }}
                                        validated
                                        currencyFieldName={'currencyID'}
                                        fieldName={!componentState.isPlanReceipt ? 'payout' : 'receipt'}></CurrencySelect>
                    </span>
                {
                    !componentState.isPlanReceipt && <><Form.Item name={'beneficiary_id'} label={'Beneficiary'}>
                        <Select placeholder={' -- SELECT BENEFICIARY --'}
                                options={componentState.currencyBeneficiaryList.map(i => {
                                    const curr = app_state.currency_list.find(k => k.id === i.account_currency);
                                    return {
                                        alt: i.nickname == null ? i.ben_legal_name : i.nickname,
                                        label: <TypographyCurrency iso_alpha_3={curr.iso_alpha_3}>{i.nickname == null ? i.ben_legal_name : i.nickname}</TypographyCurrency>,
                                        value: i.id
                                    };
                                })}
                                optionFilterProp={'alt'}></Select>
                    </Form.Item>
                        <Button style={{ marginBottom: '10px' }} type={'primary'} size={'small'} onClick={() => setComponentState(prev => ({...prev, isBenModalOpen: true}))}>Add Beneficiary</Button>
                    </>
                }
                <Divider/>
                <Form.Item name={'description'} label={'Description'}>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label="Hypothetical?" name="draft" valuePropName="checked">
                    <Switch /> <em>Is this a hypothetical (test) payment</em>
                </Form.Item>

                <Form.Item name="attachments" getValueFromEvent={(e) => e.map(i => i.name)}>
                    <FileUploader key={componentState.activePayoutId} uploaderType={'DRAGDROP'} ref={uploaderRef}></FileUploader>
                </Form.Item>
                <Modal visible={componentState.isBenModalOpen}
                       width={'900px'}
                       style={{top: 10}}
                       bodyStyle={{maxHeight: '80vh', overflowY: 'scroll', padding: '10px', background: '#E0e0e0'}}
                       onCancel={() => {
                           beneficiaryModalRef.current.resetFields();
                           setComponentState(prev => ({...prev, isBenModalOpen: false}))
                       }}
                       onOk={() => {
                           beneficiaryModalRef.current.submit();
                       }}
                       modalRender={(modal) => (
                           <Draggable
                               disabled={modalDrag.disabled}
                               bounds={modalDrag.bounds}
                               nodeRef={draggableRef}
                               onStart={(event, uiData) => onStart(event, uiData)}
                           >
                               <div ref={draggableRef}>{modal}</div>
                           </Draggable>
                       )}
                       title={
                           <div style={{ width: '100%', cursor: 'move' }}
                                onMouseOver={() => {
                                    if (modalDrag.disabled) {
                                        setModalDrag(prev => ({...prev, disabled: false}));
                                    }
                                }}
                                onMouseOut={() => {
                                    setModalDrag(prev => ({...prev, disabled: true}));
                                }}>Add New Beneficiary</div>
                       }>
                    <BeneficiaryModal clientId={app_state.current_client ? app_state.current_client.id : null}
                                      initialCurrency={5}
                                      onSubmit={(val) => {
                                          initPrefs();
                                          setComponentState(prev => ({...prev, isBenModalOpen: false}));
                                      }}
                                      ref={beneficiaryModalRef}></BeneficiaryModal>
                </Modal>
            </Form>
        </Modal>
        <Modal name={'Forwards-Modal'}
               title={'Request Coverage'}
               onCancel={() => {
                   transferForm.resetFields();
                   setComponentState(prev => ({...prev, isForwardModalOpen: false}));
               }}
               visible={componentState.isForwardModalOpen}
               onOk={() => transferForm.submit()}>
            <p>
                Select to request either a spot transfer or forward cover for your payments.
            </p>
            <Divider/>
            <Form layout={'vertical'} name={'forwards-form'} form={transferForm} onFinish={handleForwardsSubmit}>
                <Segmented defaultValue={componentState.transferType}
                           block={true}
                           style={{ marginBottom: '10px' }}
                           options={[{label: 'Spot', value: 'SPOT'}, {label: 'Forward', value: 'FORWARD'}]}
                           onChange={(val) => setComponentState(prev => ({...prev, transferType: val }))}></Segmented>
                {
                    componentState.transferType === 'FORWARD' && <Form.Item label={'Payment Due Date'} name={'payment_due_date'} normalize={(dayjsValue) =>
                        dayjsValue && dayjsValue.startOf("day").format('YYYY-MM-DD')
                    }
                                                                            getValueProps={(value) =>
                                                                                value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                                                                            }>
                        <DatePicker format={'DD/MM/YYYY'}/>
                    </Form.Item>
                }
                <Form.Item name={'to_currency'} label={'From Currency'}>
                    <Select optionFilterProp={'alt'} options={(app_state.currency_list || []).map(i => ({
                        ...i,
                        value: i.id,
                        alt: i.full_name + i.iso_alpha_3,
                        label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>
                    }))}></Select>
                </Form.Item>
                <Form.Item name={'from_currency'} label={'To Currency'}>
                    <Select optionFilterProp={'alt'} options={(app_state.currency_list || []).map(i => ({
                        ...i,
                        value: i.id,
                        alt: i.full_name + i.iso_alpha_3,
                        label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name}</TypographyCurrency>
                    }))}></Select>
                </Form.Item>
                <Form.Item name={'amount'} label={'Amount'} required={[
                    {required: true, message: ''},
                    {
                        validator: (_, value) => {
                            if (Number(value) === 0) {
                                return Promise.reject('Enter valid amount');
                            }
                            if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                                return Promise.reject('Please enter a valid number with up to 2 decimal places');
                            }
                            if (value && parseFloat(value) === 0) {
                                return Promise.reject('Amount cannot be zero');
                            }
                            return Promise.resolve();
                        },
                    }
                ]}>
                    <Input />
                </Form.Item>
                {
                    componentState.transferType === 'FORWARD' && <Form.Item name={'description'} label={'Description'}>
                        <Input.TextArea />
                    </Form.Item>
                }
            </Form>
        </Modal>
        <Modal visible={componentState.isListModalOpen}
               title={'Payouts List'}
               width={'700px'}
               onCancel={() => setComponentState(prev => ({...prev, isListModalOpen: false }))}
               cancelButtonProps={{ type: 'primary', icon: <CloseOutlined /> }}
               okButtonProps={{ hidden: true }}
               cancelText={'Close'}>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button type={'primary'} danger={true} size={'small'} onClick={() => initData()}
                        icon={<ReloadOutlined/>}>Refresh List</Button>
            </div>
            <Table rowKey={'id'}
                   key={app_state.current_client ? app_state.current_client.id : 0}
                   columns={buildColumns()}
                   size="small"
                   summary={(data) => {
                       const total = data.reduce((acc, item) => acc + item.amount, 0);
                       return <Table.Summary.Row>
                           <Table.Summary.Cell index={0}></Table.Summary.Cell>
                           <Table.Summary.Cell index={1}></Table.Summary.Cell>
                           <Table.Summary.Cell index={2}></Table.Summary.Cell>
                           <Table.Summary.Cell index={3}>Total: {new Intl.NumberFormat('en-Au', {
                               minimumFractionDigits: 2,
                               maximumFractionDigits: 2,
                               useGrouping: true
                           }).format(total)}</Table.Summary.Cell>
                       </Table.Summary.Row>
                   }}
                   dataSource={componentState.isDraft ? componentState.dataList.filter(i => i.type === 'PAYOUTS') : componentState.dataList.filter(i => i.type === 'PAYOUTS' && i.draft !== 0) }
                   loading={componentState.isPayoutListLoading}></Table>
        </Modal>
        <Modal visible={componentState.isTransferModalOpen}
               title={'Forwards and Transfers'}
               width={'1000px'}
               onCancel={() => setComponentState(prev => ({...prev, isTransferModalOpen: false }))}
               cancelButtonProps={{ type: 'primary', icon: <CloseOutlined /> }}
               okButtonProps={{ hidden: true }}
               cancelText={'Close'}>
            <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
                <Button type={'primary'} danger={true} size={'small'} onClick={() => initData()}
                        icon={<ReloadOutlined/>}>Refresh List</Button>
            </div>
            <Segmented defaultValue={componentState.transferType}
                       block={true}
                       style={{ marginBottom: '10px' }}
                       options={[{label: 'Spot', value: 'SPOT'}, {label: 'Forward', value: 'FORWARD'}]}
                       onChange={(val) => setComponentState(prev => ({...prev, transferType: val }))}></Segmented>
                <Table rowKey={'id'}
                       key={app_state.current_client ? app_state.current_client.id : 0}
                       columns={buildColumns()}
                       size="small"
                       summary={(data) => {
                           const total = data.reduce((acc, item) => acc + item.amount, 0);
                           return <Table.Summary.Row>
                               <Table.Summary.Cell index={0}></Table.Summary.Cell>
                               <Table.Summary.Cell index={1}></Table.Summary.Cell>
                               <Table.Summary.Cell index={2}></Table.Summary.Cell>
                               <Table.Summary.Cell index={3}></Table.Summary.Cell>
                               <Table.Summary.Cell index={4}>Average Forward Rate: .xxxx%</Table.Summary.Cell>
                               <Table.Summary.Cell index={5}>Total: {new Intl.NumberFormat('en-Au', {
                                   minimumFractionDigits: 2,
                                   maximumFractionDigits: 2,
                                   useGrouping: true
                               }).format(total)}</Table.Summary.Cell>
                           </Table.Summary.Row>
                       }}
                       dataSource={componentState.dataList.filter(i => componentState.transferType === 'SPOT' ? i.type === 'TRANSFERS' : i.type === 'FORWARDS')}
                       loading={componentState.isPayoutListLoading}></Table>
        </Modal>
        <Modal visible={componentState.isDraftForwardModelOpen}
               onOk={() => draftForwardForm.submit()}
               onCancel={() => {
                   draftForwardForm.resetFields();
                   setComponentState(prev => ({...prev, isDraftForwardModelOpen: false }));
               }}
               title={'Enter Hypothetical Coverage'}>
            <Form layout={'vertical'} name={'draft-forward-form'} form={draftForwardForm} onFinish={handleDraftForwardSubmit}>
                <Typography.Paragraph>
                    Use this form to enter hypothetical coverage for your future payments.
                    These will be recorded as Hypothetical forwards to help you plan your hedging requirements
                </Typography.Paragraph>
                <Form.Item normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                           rules={[{required: true, message: 'Settlement date is required'}]}
                           getValueProps={(value) =>
                               value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                           }
                           name="settlementDate"
                           label={'Settlement Date'}>
                    <DatePicker format={'DD/MM/YYYY'} />
                </Form.Item>
                <Form.Item name="currencyFromID"
                           rules={[{required: true, message: 'Currency is required'}]}
                           label={'Currency From'}>
                        <Select showSearch={true} allowClear={true} optionFilterProp={'alt'} options={app_state.currency_list.map(i => ({
                            ...i,
                            alt: `${i.full_name}${i.iso_alpha_3}`,
                            value: i.id,
                            label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.iso_alpha_3})</TypographyCurrency>
                        }))} />
                </Form.Item>
                <Form.Item name="currencyToID"
                           rules={[{required: true, message: 'Currency is required'}]}
                           label={'Currency To'}>
                    <Select showSearch={true} allowClear={true} optionFilterProp={'alt'} options={app_state.currency_list.map(i => ({
                        ...i,
                        alt: `${i.full_name}${i.iso_alpha_3}`,
                        value: i.id,
                        label: <TypographyCurrency iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.iso_alpha_3})</TypographyCurrency>
                    }))} />
                </Form.Item>
                <Form.Item name="amountTo"
                           rules={[{required: true, message: 'Amount is required'}]}
                           label={'Amount'}>
                    <InputNumber  step={0.01} />
                </Form.Item>
            </Form>
        </Modal>
    </Context.Provider>
}
const mapStateToProps = (state) => ({
    app_state: state.app_state
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ListTable(ExposureMonitor)));