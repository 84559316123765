import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import Button from 'components/CustomButtons/Button.jsx'

import ReactTable from 'react-table'
import { FormattedNumber } from 'react-intl'
import Table from 'components/Table/Table.jsx'
import { injectIntl } from 'react-intl'

// import * as jsPDF from 'jspdf'
// import html2canvas from 'html2canvas'

import { API } from 'aws-amplify'
import { getLetterheadLogo, receiptFooter } from '../../libs/branding'

// const axios = require('axios')
var moment = require('moment')

const style = {
    root: {},
    pageHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
    },
    pageSubHeadings: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
    },
    tableHeaders: {
        width: 200,
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
        fontSize: '12px',
    },
    tableCells: {
        textAlign: 'left',
        verticalAlign: 'top',
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: '12px',
    },
    backgroundBlue: {
        backgroundColor: '#348feb40',
        fontSize: '12px',
    },
    topLetterheadLogo: {
        width: '300px',
    },
}

// let language = navigator.language.split(/[-_]/)[0];
const TheadComponent = props => null // a component returning null (to hide) or you could write as per your requirement

class RemittancePage extends React.Component {
    state = {
        value: 0,
        currencies_list_priority: [],
        map_data: {},
        beneficiaries_list: [],
        beneficiary_id: '',
        transfer_heading: 'Current Transfers',
        transfer: {},
        payouts_list: [],
    }

    componentDidMount() {
        this.updateTransferData(this.props.match.params.transfer_id)
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.prefill !== this.props.prefill) {
            this.updateTransferData()
        }
    }

    updateTransferData(option_value) {
        this.setState({ transfer_id: option_value })

        API.get('transfers', `/get-remittance/id/${option_value}`)
            .then(response => {
                console.log(response)
                this.setState({
                    transfer: response.transfer,
                    payouts_list: response.payouts,
                    currency_from: response.currency_from,
                    currency_to: response.currency_to,
                    client: response.client,
                    team: response.team,
                    domain: response.domain,
                    entityBankAccount: response.entityBankAccount,
                    entityBank: response.entityBank,
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true
        }
        return false
    }

    isLargerThanNumber(value, number) {
        if (value !== '' && value >= number) {
            return true
        }
        return false
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case 'length':
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            case 'isLargerThanNumber':
                if (
                    this.isLargerThanNumber(
                        event.target.value,
                        stateNameEqualTo
                    )
                ) {
                    this.setState({ [stateName + '_state']: 'success' })
                } else {
                    this.setState({ [stateName + '_state']: 'error' })
                }
                break
            default:
                break
        }
        this.setState({ [stateName]: event.target.value })
    }

    handleChange = (event, value) => {
        this.setState({ value })
    }
    handleChangeIndex = index => {
        this.setState({ value: index })
    }

    buildData = () => {
        let return_data = []

        return_data.push({ date: ['DATE', 'sdsaa'] })

        return return_data
    }

    payoutItemTable = () => {
        const { classes } = this.props
        // console.log(id)
        // console.log(this.state)
        // console.log(this.state.payouts_list[id])

        let totalPayment = 0
        for (let i = 0; i < this.state.payouts_list.length; i++) {
            totalPayment += Number.parseFloat(
                this.state.payouts_list[i].amount_to
            )
        }

        let returnValue = []

        if (!this.state.payouts_list.length) {
            returnValue.push(
                <tr>
                    <td>
                        <table style={{ width: '100%' }}>
                            <tbody style={{ width: '100%' }}>
                                <tr style={{ width: '100%' }}>
                                    <td className={classes.tableCells}>
                                        <table style={{ width: '100%' }}>
                                            <tbody>
                                                <tr
                                                    className={
                                                        classes.backgroundBlue
                                                    }
                                                >
                                                    <th
                                                        className={
                                                            classes.tableHeaders
                                                        }
                                                    >
                                                        Beneficiary
                                                    </th>
                                                    <td
                                                        className={
                                                            classes.tableCells
                                                        }
                                                    >
                                                        <React.Fragment>
                                                            <strong>
                                                                Payout will be
                                                                made to your
                                                                currency balance
                                                            </strong>
                                                        </React.Fragment>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th
                                                        className={
                                                            classes.tableHeaders
                                                        }
                                                    >
                                                        Amount Paid
                                                    </th>
                                                    <td
                                                        className={
                                                            classes.tableCells
                                                        }
                                                    >
                                                        <React.Fragment>
                                                            <strong>
                                                                {this.props.intl.formatNumber(
                                                                    this.state
                                                                        .transfer
                                                                        .amount_to,
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                )}
                                                                {` `}
                                                                <div
                                                                    className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                                />
                                                                {` `}
                                                                {this.state.currency_to.iso_alpha_3.toUpperCase()}
                                                            </strong>
                                                        </React.Fragment>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            )
        }

        for (let i = 0; i < this.state.payouts_list.length; i++) {
            const payout = this.state.payouts_list[i]
            console.log(payout)
            if (
                i == 0 ||
                (i > 0 &&
                    payout.beneficiary_id !=
                        this.state.payouts_list[0].beneficiary_id)
            ) {
                // let beneficiaryNickname = payout.nickname
                let beneficiaryNickname = payout.ben_legal_name
                if (payout.holdingAccountPayee) {
                    beneficiaryNickname = `Payment made to ${this.state.currency_to.iso_alpha_3.toUpperCase()} Currency Balance`
                }

                returnValue.push(
                    <tr>
                        <table>
                            <tbody>
                                <tr>
                                    <th className={classes.tableHeaders}>
                                        Beneficiary
                                    </th>
                                    <td className={classes.tableCells}>
                                        {beneficiaryNickname}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classes.tableHeaders}>
                                        Beneficiary Address
                                    </th>
                                    <td className={classes.tableCells}>
                                        <React.Fragment>
                                            {payout.ben_address_line_1}
                                            &nbsp;
                                            {payout.ben_address_line_2}
                                            &nbsp;
                                            {payout.ben_address_suburb}
                                            &nbsp;
                                            {payout.ben_address_state}
                                            &nbsp;
                                            {payout.ben_address_postcode}
                                            &nbsp;
                                            {payout.beneficiary_country_name}
                                        </React.Fragment>
                                    </td>
                                </tr>
                                {payout.internalAccount && (
                                    <>
                                        <tr>
                                            <th
                                                className={classes.tableHeaders}
                                            >
                                                Payment Type
                                            </th>
                                            <td className={classes.tableCells}>
                                                Internal Account Payment
                                            </td>
                                        </tr>
                                    </>
                                )}{' '}
                                {/* payout.internalAccount &&  */}
                                {!payout.internalAccount && (
                                    <>
                                        <tr>
                                            <th
                                                className={classes.tableHeaders}
                                            >
                                                Bank Name
                                            </th>
                                            <td className={classes.tableCells}>
                                                {payout.bank_legal_name ? (
                                                    <span>
                                                        {payout.bank_legal_name}
                                                        <br />
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        </tr>

                                        <tr>
                                            <th
                                                className={classes.tableHeaders}
                                            >
                                                Bank Address
                                            </th>
                                            <td className={classes.tableCells}>
                                                <React.Fragment>
                                                    {payout.bank_address_line_1}
                                                    &nbsp;
                                                    {payout.bank_address_line_2}
                                                    &nbsp;
                                                    {payout.bank_address_suburb}
                                                    &nbsp;
                                                    {payout.bank_address_state}
                                                    &nbsp;
                                                    {payout.bank_address_state}
                                                    &nbsp;
                                                    {
                                                        payout.bank_address_postcode
                                                    }
                                                    &nbsp;
                                                    {
                                                        payout.beneficiary_country_name
                                                    }
                                                </React.Fragment>
                                            </td>
                                        </tr>
                                    </>
                                )}
                                {payout.swift_code && (
                                    <tr>
                                        <th className={classes.tableHeaders}>
                                            SWIFT Code
                                        </th>
                                        <td className={classes.tableCells}>
                                            {payout.swift_code ? (
                                                <span>
                                                    {payout.swift_code}
                                                    <br />
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {payout.account_number && (
                                    <tr
                                    // className={
                                    //     payout.swift_code ? '' : classes.backgroundBlue
                                    // }
                                    >
                                        <th className={classes.tableHeaders}>
                                            Account Number
                                        </th>
                                        <td className={classes.tableCells}>
                                            {payout.account_number ? (
                                                <span>
                                                    {payout.account_number}
                                                    <br />
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {payout.iban && (
                                    <tr>
                                        <th className={classes.tableHeaders}>
                                            IBAN
                                        </th>
                                        <td className={classes.tableCells}>
                                            {payout.iban ? (
                                                <span>
                                                    {payout.iban}
                                                    <br />
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {payout.bsb_code && (
                                    <tr>
                                        <th className={classes.tableHeaders}>
                                            BSB
                                        </th>
                                        <td className={classes.tableCells}>
                                            {payout.bsb_code ? (
                                                <span>
                                                    {payout.bsb_code}
                                                    <br />
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {payout.aba_routing_number && (
                                    <tr>
                                        <th className={classes.tableHeaders}>
                                            ABA Routing
                                        </th>
                                        <td className={classes.tableCells}>
                                            {payout.aba_routing_number ? (
                                                <span>
                                                    {payout.aba_routing_number}
                                                    <br />
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {payout.sort_code && (
                                    <tr>
                                        <th className={classes.tableHeaders}>
                                            Sort Code
                                        </th>
                                        <td className={classes.tableCells}>
                                            {payout.sort_code ? (
                                                <span>
                                                    {payout.sort_code}
                                                    <br />
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {payout.transit_code && (
                                    <tr>
                                        <th className={classes.tableHeaders}>
                                            Transit Code
                                        </th>
                                        <td className={classes.tableCells}>
                                            {payout.transit_code ? (
                                                <span>
                                                    {payout.transit_code}
                                                    <br />
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {payout.bank_code && (
                                    <tr>
                                        <th className={classes.tableHeaders}>
                                            Bank Code
                                        </th>
                                        <td className={classes.tableCells}>
                                            {payout.bank_code ? (
                                                <span>
                                                    {payout.bank_code}
                                                    <br />
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {payout.detail_1 && (
                                    <tr>
                                        <th className={classes.tableHeaders}>
                                            Client Ref/Inv No
                                        </th>
                                        <td className={classes.tableCells}>
                                            <React.Fragment>
                                                {payout.detail_1}
                                            </React.Fragment>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <th className={classes.tableHeaders}>
                                        Amount to be Paid
                                    </th>
                                    <td className={classes.tableCells}>
                                        <React.Fragment>
                                            {this.props.intl.formatNumber(
                                                // payout.amount_to,
                                                totalPayment,
                                                {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            )}
                                            {` `}
                                            <img
                                                src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_to.iso_alpha_3.toLowerCase()}.png`}
                                                width="24px"
                                                height="16px"
                                                style={{
                                                    verticalAlign: 'middle',
                                                    marginBottom: '4px',
                                                    marginRight: '5px',
                                                }}
                                            />
                                            {this.state.currency_to.iso_alpha_3.toUpperCase()}
                                        </React.Fragment>
                                    </td>
                                </tr>
                                {/*</tbody>*/}
                                {/*</table>*/}
                                {/*</td>*/}
                                {/*</tr>*/}
                            </tbody>
                        </table>
                        {/* })
} */}
                    </tr>
                )
            }
            if (
                this.state.payouts_list.length > 1 &&
                payout.beneficiary_id ==
                    this.state.payouts_list[0].beneficiary_id
            ) {
                // console.log(payout.amount)

                returnValue.push(
                    <tr>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                {/* {
    this.state.payouts_list.map((item) => {
    return <tr> */}
                                <tr style={{ width: '100%' }}>
                                    {/* <td style={{ verticalAlign: "top", paddingRight: 20, paddingLeft: 8, paddingTop: 10, paddingBottom: 10 }}> */}
                                    <td>
                                        <table style={{ width: '100%' }}>
                                            <tbody style={{ width: '100%' }}>
                                                <tr
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    <td
                                                        className={
                                                            classes.tableCells
                                                        }
                                                    >
                                                        <table
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <th
                                                                        className={
                                                                            classes.tableHeaders
                                                                        }
                                                                    />
                                                                    <td
                                                                        className={
                                                                            classes.tableCells
                                                                        }
                                                                    >
                                                                        <React.Fragment
                                                                        >
                                                                            <em>
                                                                                Payout{' '}
                                                                                {i +
                                                                                    1}

                                                                                :
                                                                            </em>
                                                                            &nbsp;&nbsp;&nbsp;
                                                                            {this.props.intl.formatNumber(
                                                                                payout.amount_to,
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                }
                                                                            )}
                                                                            {` `}
                                                                            <div
                                                                                className={`currency-flag currency-flag-${this.state.currency_to.iso_alpha_3.toLowerCase()}`}
                                                                            />
                                                                            {` `}
                                                                            {this.state.currency_to.iso_alpha_3.toUpperCase()}
                                                                        </React.Fragment>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </tr>
                )
            }
        }
        return returnValue
    }

    render() {
        const { classes } = this.props

        console.log('state', this.state)

        if (this.state.payouts_list.length === 0) {
            return null
        }

        const logo = getLetterheadLogo(this.state.team.id)

        const clientID = this.state.transfer.client_id
        const transferID = this.state.transfer.id

        let adjusted_remittance_reference = transferID
        if (
            this.state.entityBankAccount &&
            this.state.entityBankAccount.reference_mandatory
        ) {
            adjusted_remittance_reference = this.state.entityBankAccount
                .reference_mandatory
        } else if (
            this.state.entityBankAccount &&
            this.state.entityBankAccount.reference_prefix
        ) {
            adjusted_remittance_reference = this.state.entityBankAccount.reference_prefix + transferID
        }

        const remittanceTotal = Number(this.state.transfer.amount_from)
        const feeVisibleAmount = Number(this.state.transfer.fee_visible_amount)
        const totalRemittance = remittanceTotal + feeVisibleAmount

        return (
            <React.Fragment>
                <div>
                    <GridContainer alignItems="stretch">
                        <GridItem xs={12}>
                            <Card style={{ height: '95%' }}>
                                <CardBody>
                                    <div
                                        id="divToPrint"
                                        className="mt4"
                                        style={{
                                            hidden: 'hidden',

                                            width: '210mm',
                                            minHeight: '297mm',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            padding: '10mm',
                                        }}
                                    >
                                        <img
                                            src={logo}
                                            alt="logo"
                                            className={
                                                classes.topLetterheadLogo
                                            }
                                        />
                                        <h3
                                            className={classes.pageHeadings}
                                            style={{ textAlign: 'center' }}
                                        >
                                            Settlement Instructions
                                        </h3>
                                        <h6
                                            style={{
                                                textAlign: 'center',
                                                marginTop: 4,
                                            }}
                                        >
                                            {this.state.client.nickname} -
                                            Client ID: {this.state.client.id}
                                        </h6>

                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <h5
                                                    className={
                                                        classes.pageSubHeadings
                                                    }
                                                >
                                                    Transaction Details
                                                </h5>
                                                <p
                                                    style={{
                                                        fontSize: '11px',
                                                        lineHeight: '0.9',
                                                    }}
                                                >
                                                    Your foreign exchange
                                                    transaction is now in
                                                    process and awaiting
                                                    settlement.
                                                </p>
                                                <div
                                                    style={{
                                                        border:
                                                            '1px solid #a1a1a1',
                                                    }}
                                                >
                                                    <table
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <tbody
                                                            style={{
                                                                width: '100%',
                                                                fontsize:
                                                                    '10px',
                                                            }}
                                                        >
                                                            <tr
                                                                className={
                                                                    classes.backgroundBlue
                                                                }
                                                            >
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    You Sell
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    {this.state
                                                                        .transfer
                                                                        .amount_from ? (
                                                                        <React.Fragment
                                                                        >
                                                                            {this.props.intl.formatNumber(
                                                                                this
                                                                                    .state
                                                                                    .transfer
                                                                                    .amount_from,
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                }
                                                                            )}
                                                                            &nbsp;
                                                                            <img
                                                                                src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_from.iso_alpha_3.toLowerCase()}.png`}
                                                                                width="24px"
                                                                                height="16px"
                                                                                style={{
                                                                                    verticalAlign:
                                                                                        'middle',
                                                                                    marginBottom:
                                                                                        '4px',
                                                                                }}
                                                                            />
                                                                            &nbsp;
                                                                            {this.state.currency_from.iso_alpha_3.toUpperCase()}{' '}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    You Buy
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    {this.state
                                                                        .transfer
                                                                        .amount_to ? (
                                                                        <React.Fragment
                                                                        >
                                                                            {this.props.intl.formatNumber(
                                                                                this
                                                                                    .state
                                                                                    .transfer
                                                                                    .amount_to,
                                                                                {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                }
                                                                            )}
                                                                            &nbsp;
                                                                            <img
                                                                                src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_to.iso_alpha_3.toLowerCase()}.png`}
                                                                                width="24px"
                                                                                height="16px"
                                                                                style={{
                                                                                    verticalAlign:
                                                                                        'middle',
                                                                                    marginBottom:
                                                                                        '4px',
                                                                                }}
                                                                            />
                                                                            &nbsp;
                                                                            {this.state.currency_to.iso_alpha_3.toUpperCase()}{' '}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                className={
                                                                    classes.backgroundBlue
                                                                }
                                                            >
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    Exchange
                                                                    Rate
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    {this.state
                                                                        .transfer
                                                                        .client_rate ? (
                                                                        <React.Fragment
                                                                        >
                                                                            {this.props.intl.formatNumber(
                                                                                this
                                                                                    .state
                                                                                    .transfer
                                                                                    .client_rate,
                                                                                {
                                                                                    minimumFractionDigits: 4,
                                                                                    maximumFractionDigits: 4,
                                                                                }
                                                                            )}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    className={
                                                                        classes.tableHeaders
                                                                    }
                                                                >
                                                                    Settlement
                                                                    Date
                                                                </th>
                                                                <td
                                                                    className={
                                                                        classes.tableCells
                                                                    }
                                                                >
                                                                    {this.state
                                                                        .transfer
                                                                        .settlement_date ? (
                                                                        <React.Fragment
                                                                        >
                                                                            {moment(
                                                                                this
                                                                                    .state
                                                                                    .transfer
                                                                                    .settlement_date
                                                                            ).format(
                                                                                'DD/MM/YYYY'
                                                                            )}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <p
                                                    style={{
                                                        fontSize: '11px',
                                                        lineHeight: '0.9',
                                                        marginTop: 12,
                                                    }}
                                                >
                                                    {this.state
                                                        .entityBankAccount ? (
                                                        <>
                                                            Please remit your{' '}
                                                            <strong>
                                                                {totalRemittance.toFixed(
                                                                    2
                                                                )}
                                                                {` `}
                                                            </strong>
                                                            <img
                                                                src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_from.iso_alpha_3.toLowerCase()}.png`}
                                                                width="24px"
                                                                height="16px"
                                                                style={{
                                                                    verticalAlign:
                                                                        'middle',
                                                                    marginBottom:
                                                                        '4px',
                                                                }}
                                                            />
                                                            {` `}
                                                            <strong>
                                                                {
                                                                    this.state
                                                                        .currency_from
                                                                        .full_name
                                                                }
                                                                {` `}
                                                            </strong>
                                                            to our Client
                                                            Settlement Account
                                                            {` `}
                                                            using your Mandatory
                                                            Reference{' '}
                                                            <strong>
                                                                {
                                                                    adjusted_remittance_reference
                                                                }
                                                            </strong>
                                                            .{`  `}
                                                            {feeVisibleAmount ? (
                                                                <>
                                                                    <span
                                                                        style={{
                                                                            fontsize:
                                                                                '11px',
                                                                        }}
                                                                    >
                                                                        <br />
                                                                        This
                                                                        amount
                                                                        includes
                                                                        the
                                                                        conversion
                                                                        amount
                                                                        of{' '}
                                                                        {remittanceTotal.toFixed(
                                                                            2
                                                                        )}{' '}
                                                                        and a
                                                                        fee of{' '}
                                                                        {feeVisibleAmount.toFixed(
                                                                            2
                                                                        )}
                                                                        .
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            The amount of{' '}
                                                            <strong>
                                                                {totalRemittance.toFixed(
                                                                    2
                                                                )}
                                                                {` `}
                                                            </strong>
                                                            <img
                                                                src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_from.iso_alpha_3.toLowerCase()}.png`}
                                                                width="24px"
                                                                height="16px"
                                                                style={{
                                                                    verticalAlign:
                                                                        'middle',
                                                                    marginBottom:
                                                                        '4px',
                                                                }}
                                                            />
                                                            {` `}
                                                            <strong>
                                                                {
                                                                    this.state
                                                                        .currency_from
                                                                        .full_name
                                                                }
                                                                {` `}
                                                            </strong>
                                                            will be drawn from
                                                            your Currency
                                                            Balance to fund this
                                                            transaction and
                                                            credited to your
                                                            nominated
                                                            beneficiary.
                                                            {`  `}
                                                            {feeVisibleAmount ? (
                                                                <>
                                                                    <span
                                                                        style={{
                                                                            fontsize:
                                                                                '11px',
                                                                        }}
                                                                    >
                                                                        <br />
                                                                        This
                                                                        amount
                                                                        includes
                                                                        the
                                                                        conversion
                                                                        amount
                                                                        of{' '}
                                                                        {remittanceTotal.toFixed(
                                                                            2
                                                                        )}{' '}
                                                                        and a
                                                                        fee of{' '}
                                                                        {feeVisibleAmount.toFixed(
                                                                            2
                                                                        )}
                                                                        .
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    )}
                                                </p>

                                                {this.state
                                                    .entityBankAccount && (
                                                    <>
                                                        <h5
                                                            className={
                                                                classes.pageSubHeadings
                                                            }
                                                        >
                                                            Client Settlement
                                                            Account
                                                        </h5>

                                                        <div
                                                            style={{
                                                                border:
                                                                    '1px solid #a1a1a1',
                                                            }}
                                                        >
                                                            <table
                                                                style={{
                                                                    width:
                                                                        '100%',
                                                                    fontsize:
                                                                        '10px',
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        className={
                                                                            classes.backgroundBlue
                                                                        }
                                                                    >
                                                                        <th
                                                                            className={
                                                                                classes.tableHeaders
                                                                            }
                                                                        >
                                                                            Deposit
                                                                            Amount
                                                                        </th>
                                                                        <td
                                                                            className={
                                                                                classes.tableCells
                                                                            }
                                                                        >
                                                                            <strong
                                                                            >
                                                                                {totalRemittance.toFixed(
                                                                                    2
                                                                                )}
                                                                                {` `}
                                                                            </strong>
                                                                            <img
                                                                                src={`https://wise.com/public-resources/assets/flags/rectangle/${this.state.currency_from.iso_alpha_3.toLowerCase()}.png`}
                                                                                width="24px"
                                                                                height="16px"
                                                                                style={{
                                                                                    verticalAlign:
                                                                                        'middle',
                                                                                    marginBottom:
                                                                                        '4px',
                                                                                }}
                                                                            />
                                                                            {` `}
                                                                            <strong
                                                                            >
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .currency_from
                                                                                        .full_name
                                                                                }
                                                                                {` `}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th
                                                                            className={
                                                                                classes.tableHeaders
                                                                            }
                                                                        >
                                                                            Deposit
                                                                            Reference
                                                                        </th>
                                                                        <td
                                                                            className={
                                                                                classes.tableCells
                                                                            }
                                                                        >
                                                                            <strong
                                                                            >
                                                                                {
                                                                                    adjusted_remittance_reference
                                                                                }
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                    <tr
                                                                        className={
                                                                            classes.backgroundBlue
                                                                        }
                                                                    >
                                                                        <th
                                                                            className={
                                                                                classes.tableHeaders
                                                                            }
                                                                        >
                                                                            Account
                                                                            Name
                                                                        </th>
                                                                        <td
                                                                            className={
                                                                                classes.tableCells
                                                                            }
                                                                        >
                                                                            {this
                                                                                .state
                                                                                .entityBankAccount
                                                                                .eba_accout_name && (
                                                                                <span
                                                                                >
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .entityBankAccount
                                                                                            .eba_accout_name
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th
                                                                            className={
                                                                                classes.tableHeaders
                                                                            }
                                                                        >
                                                                            Account
                                                                            Address
                                                                        </th>
                                                                        <td
                                                                            className={
                                                                                classes.tableCells
                                                                            }
                                                                        >
                                                                            <React.Fragment
                                                                            >
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBankAccount
                                                                                        .address_line_1
                                                                                }
                                                                                {` `}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBankAccount
                                                                                        .address_line_2
                                                                                }
                                                                                {` `}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBankAccount
                                                                                        .address_suburb
                                                                                }
                                                                                {` `}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBankAccount
                                                                                        .address_state
                                                                                }
                                                                                {` `}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBankAccount
                                                                                        .address_postcode
                                                                                }
                                                                                <br />
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBankAccount
                                                                                        .entity_bank_account_address_country
                                                                                }
                                                                            </React.Fragment>
                                                                        </td>
                                                                    </tr>

                                                                    <tr
                                                                        className={
                                                                            classes.backgroundBlue
                                                                        }
                                                                    >
                                                                        <th
                                                                            className={
                                                                                classes.tableHeaders
                                                                            }
                                                                        >
                                                                            Bank
                                                                            Name
                                                                        </th>
                                                                        <td
                                                                            className={
                                                                                classes.tableCells
                                                                            }
                                                                        >
                                                                            <React.Fragment
                                                                            >
                                                                                <strong
                                                                                >
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .entityBank
                                                                                            .eb_long_name
                                                                                    }
                                                                                </strong>
                                                                            </React.Fragment>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th
                                                                            className={
                                                                                classes.tableHeaders
                                                                            }
                                                                        >
                                                                            Bank
                                                                            Address
                                                                        </th>
                                                                        <td
                                                                            className={
                                                                                classes.tableCells
                                                                            }
                                                                        >
                                                                            <React.Fragment
                                                                            >
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBank
                                                                                        .address_line_1
                                                                                }
                                                                                {` `}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBank
                                                                                        .address_line_2
                                                                                }
                                                                                {` `}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBank
                                                                                        .address_suburb
                                                                                }
                                                                                {` `}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBank
                                                                                        .address_state
                                                                                }
                                                                                {` `}
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBank
                                                                                        .address_postcode
                                                                                }
                                                                                <br />
                                                                                {
                                                                                    this
                                                                                        .state
                                                                                        .entityBank
                                                                                        .entity_bank_account_address_country
                                                                                }
                                                                            </React.Fragment>
                                                                        </td>
                                                                    </tr>
                                                                    {this.state
                                                                        .entityBankAccount
                                                                        .eba_swift && (
                                                                        <tr
                                                                            className={
                                                                                classes.backgroundBlue
                                                                            }
                                                                        >
                                                                            <th
                                                                                className={
                                                                                    classes.tableHeaders
                                                                                }
                                                                            >
                                                                                SWIFT
                                                                                Code
                                                                            </th>
                                                                            <td
                                                                                className={
                                                                                    classes.tableCells
                                                                                }
                                                                            >
                                                                                <React.Fragment
                                                                                >
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .entityBankAccount
                                                                                            .eba_swift
                                                                                    }
                                                                                </React.Fragment>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state
                                                                        .entityBankAccount
                                                                        .eba_sortcode && (
                                                                        <tr
                                                                            className={
                                                                                classes.backgroundBlue
                                                                            }
                                                                        >
                                                                            <th
                                                                                className={
                                                                                    classes.tableHeaders
                                                                                }
                                                                            >
                                                                                Sort
                                                                                Code
                                                                            </th>
                                                                            <td
                                                                                className={
                                                                                    classes.tableCells
                                                                                }
                                                                            >
                                                                                <React.Fragment
                                                                                >
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .entityBankAccount
                                                                                            .eba_sortcode
                                                                                    }
                                                                                </React.Fragment>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state
                                                                        .entityBankAccount
                                                                        .eba_bsb && (
                                                                        <tr>
                                                                            <th
                                                                                className={
                                                                                    classes.tableHeaders
                                                                                }
                                                                            >
                                                                                BSB
                                                                            </th>
                                                                            <td
                                                                                className={
                                                                                    classes.tableCells
                                                                                }
                                                                            >
                                                                                <React.Fragment
                                                                                >
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .entityBankAccount
                                                                                            .eba_bsb
                                                                                    }
                                                                                </React.Fragment>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state
                                                                        .entityBankAccount
                                                                        .aba_routing_number && (
                                                                        <tr
                                                                            className={
                                                                                classes.backgroundBlue
                                                                            }
                                                                        >
                                                                            <th
                                                                                className={
                                                                                    classes.tableHeaders
                                                                                }
                                                                            >
                                                                                ABA
                                                                                Routing
                                                                            </th>
                                                                            <td
                                                                                className={
                                                                                    classes.tableCells
                                                                                }
                                                                            >
                                                                                <React.Fragment
                                                                                >
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .entityBankAccount
                                                                                            .aba_routing_number
                                                                                    }
                                                                                </React.Fragment>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state
                                                                        .entityBankAccount
                                                                        .eba_accout_number && (
                                                                        <tr>
                                                                            <th
                                                                                className={
                                                                                    classes.tableHeaders
                                                                                }
                                                                            >
                                                                                Account
                                                                                Number
                                                                            </th>
                                                                            <td
                                                                                className={
                                                                                    classes.tableCells
                                                                                }
                                                                            >
                                                                                <React.Fragment
                                                                                >
                                                                                    {
                                                                                        this
                                                                                            .state
                                                                                            .entityBankAccount
                                                                                            .eba_accout_number
                                                                                    }
                                                                                </React.Fragment>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.entityBankAccount.iban && (
                                                                        <tr>
                                                                            <th className={classes.tableHeaders}>
                                                                                IBAN
                                                                            </th>
                                                                            <td className={classes.tableCells}>
                                                                                <React.Fragment>
                                                                                    {this.state.entityBankAccount.iban}
                                                                                </React.Fragment>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.entityBank.intermediary_bank && (
                                                                      <tr className={classes.backgroundBlue}>
                                                                          <th className={classes.tableHeaders}>Intermediary Bank</th>
                                                                            <td className={classes.tableCells}>
                                                                                <React.Fragment>
                                                                                    {this.state.entityBank.intermediary_bank}
                                                                                </React.Fragment>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.entityBank.intermediary_address && (
                                                                        <tr>
                                                                            <th className={classes.tableHeaders}>Intermediary Bank Address</th>
                                                                            <td className={classes.tableCells}>
                                                                                <React.Fragment>
                                                                                    {this.state.entityBank.intermediary_address}
                                                                                </React.Fragment>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.entityBank.intermediary_swift && (
                                                                      <tr className={classes.backgroundBlue}>
                                                                          <th className={classes.tableHeaders}>Intermediary Bank Swift</th>
                                                                            <td className={classes.tableCells}>
                                                                                <React.Fragment>
                                                                                    {this.state.entityBank.intermediary_swift}
                                                                                </React.Fragment>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </>
                                                )}
                                                <p
                                                    style={{
                                                        fontSize: '11px',
                                                        lineHeight: '0.9',
                                                        marginTop: 12,
                                                    }}
                                                >
                                                    Once payment is received,
                                                    settlement will be made to
                                                    your chosen beneficiary
                                                    below.
                                                </p>
                                            </GridItem>
                                        </GridContainer>

                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <h5
                                                    className={
                                                        classes.pageSubHeadings
                                                    }
                                                >
                                                    Beneficiary Details
                                                </h5>
                                                <div
                                                    style={{
                                                        border:
                                                            '3px double #a1a1a1',
                                                    }}
                                                >
                                                    <table
                                                        style={{
                                                            width: '100%',
                                                            fontsize: '10px',
                                                        }}
                                                    >
                                                        <tbody>
                                                            {this.payoutItemTable()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </GridItem>
                                        </GridContainer>

                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <p
                                                    style={{
                                                        fontSize: '11px',
                                                        lineHeight: '0.9',
                                                        marginTop: 12,
                                                    }}
                                                >
                                                    If any of the above details
                                                    are incorrect, please
                                                    contact us immediately.
                                                </p>
                                            </GridItem>
                                        </GridContainer>

                                        <GridContainer justify="center">
                                            <GridItem xs={12} md={12}>
                                                <div>
                                                    {receiptFooter(
                                                        this.state.transfer
                                                            .client_team_id
                                                    )}
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.app_state,
    }
}

const RemittancePageContainer = connect(mapStateToProps)(
    injectIntl(withRouter(withStyles(style)(RemittancePage)))
)

export default RemittancePageContainer
